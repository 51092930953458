<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-flex xs12>
              <span class="heading">Daily Report</span>
            </v-flex>
            <v-flex xs12 pt-4>
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm4 md4 lg4 pl-md-2 pl-lg-2 pl-sm-2>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="From Date"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="itemform"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu = false"
                          :max="blockdate"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-md-2 pl-lg-2 pl-sm-2>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="todate"
                            label="To Date"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!date"
                            class="itemform"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="todate"
                          @input="menu1 = false"
                          :min="date"
                          :max="blockdate"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                      <v-btn
                        color="#fdac53"
                        class="downlink"
                        target="_blank"
                        @click="getPDF()"
                      >
                        <span
                          style="
                            font-size: 12px;
                            font-family: poppinssemibold;
                            color: black;
                          "
                        >
                          <v-icon color="black"
                            >mdi-file-pdf-box</v-icon
                          >DOWNLOAD</span
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      const currentDate = new Date().toISOString().substr(0, 10);
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        menu: false,
        menu1: false,
        todate: currentDate,
        date: currentDate,
        blockdate: currentDate,
      };
    },
    methods: {
      getPDF() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/daily/report/pdf",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          responseType: "blob",
          params: {
            fromDate: this.date,
            toDate: this.todate,
          },
        })
          .then((response) => {
            this.appLoading = false;
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "DailyReport.pdf");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  .tableheading {
    font-family: poppinsregular;
    font-size: 15px !important;
    color: black !important;
    text-transform: uppercase !important;
  }
  .tablesubheading {
    font-family: poppinsregular;
    font-size: 13px !important;
  }
  </style>